<div *ngIf="dataDialog"
    class=" col-12 bg-danger w-100 d-flex flex-row bg-white text-center justify-content-between align-items-center">
    <div class="pt-2 px-3">
        <h3 class="mb-0">Live Tracking</h3>
    </div>

    <div class="p-2" style="cursor: pointer;" (click)="onCloseClick({status: false, message: ''})">
        <i class="bi bi-x fs-5"></i>
    </div>
</div>

<div class="col-12 d-flex flex-column bg-white text-center justify-content-center align-items-center"
    style="position: relative;">
    <!-- Overlay Box -->

    <div *ngIf="dataDialog" class="overlay-box d-flex flex-row justify-content-between align-items-center p-3"
        style="top: 14px !important;">
        <div class="d-flex flex-row justify-content-start align-items-center gap-3 ">
            <div class="badge">
                1001
            </div>

            <div class="text-start">
                <div class="fw-bold">Luton</div>
                <small>Double Curtain</small>
            </div>
            <div class="text-start">
                <small>Driver Name</small>
                <div>Peter Parker</div>
            </div>
            <div class="text-start">
                <small>Customer Ref</small>
                <div>S00216216</div>
            </div>

            <div class="text-start">
                <small>Vehicle ID</small>
                <div>63005466</div>
            </div>

            <div class="text-start">
                <small>Agreed Rate:</small>
                <div>£ 50</div>
            </div>

            <div class="text-start">
                <small>Submitted On:</small>
                <div>18/12/2024</div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-end align-items-center">



            <button class="btn btn-secondary btn-sm ms-3 d-flex flex-row align-items-center"
                style="font-size: 0.9em !important; background-color: #CDEBDD !important; color: #438868 !important;"><i
                    class="bi bi-telephone me-2" style=" font-size:1.2em; color: #438868 !important;"></i> Call
                Driver</button>

            <div class="d-flex align-items-center ms-2"
                style="background-color: #E8EDF3; padding: 7px 12px 7px 12px; border-radius: 4px;">
                <i class="bi bi-chat-left-text text-primary"></i>
            </div>
        </div>
    </div>


    <div *ngIf="!dataDialog" class="overlay-box d-flex flex-row justify-content-between align-items-center p-3">
        <div class="d-flex flex-row justify-content-start align-items-center gap-3 ">
            <div class="badge">
                1001
            </div>

            <div class="text-start">
                <div class="fw-bold text-primary ">Luton</div>
                <small>Double Curtain</small>
            </div>
            <div class="text-start">
                <small>Current Location</small>
                <div>Peterborough</div>
            </div>
            <div class="text-start">
                <small>Origin</small>
                <div>London</div>
            </div>
        </div>

        <div class="d-flex flex-row justify-content-end align-items-center">
            <div class="d-flex align-items-center">
                <span class="me-1">Verified By</span>
                <i class="verified-icon bi bi-shield-fill-check "></i>
            </div>
            <button class="btn btn-secondary btn-sm ms-3">Book Now</button>
        </div>
    </div>
    <!-- Close Icon -->
    <i *ngIf="!dataDialog" class="bi bi-x-circle close-icon" (click)="onCloseClick({status: false, message: ''})"></i>
    <!-- Map -->
    <div id="map" class="col-12 map"></div>


    <div *ngIf="dataDialog"
        class="col-12 d-flex flex-md-column flex-row justify-content-between align-items-center milestone-div p-3">
        <div
            class="milestone-div col-xl-10 col-lg-12 col-md-11 col-5 d-md-flex flex-md-row d-none justify-content-between align-items-xl-center align-items-start bar-top-header py-md-0 py-2">
            <p>Origin</p>
            <p>
                Date: 12/11/2024
                &nbsp; | &nbsp; Time: 11:30 AM - 03:30 PM
                &nbsp; | &nbsp; Distance: 486.7 miles
            </p>
            <p>Destination</p>
        </div>

        <div
            class=" milestone-div d-flex flex-md-row flex-column col-md-10 col-2 justify-content-md-start justify-content-center align-items-center py-2">
            <i class="bi bi-check fs-4 point success"></i>
            <div class="progress-line line-success"></div>
            <i class="bi bi-check fs-4 point success"></i>
            <div class="progress-line line-warning"></div>
            <i class="bi bi-exclamation-triangle fs-6 p-1 point warning"></i>
            <div class="progress-line "></div>
            <i class="bi bi-circle fs-4 point"></i>
        </div>


        <div
            class="milestone-div col-md-12 col-6 d-flex flex-md-row flex-column justify-content-between align-items-xl-center align-items-start py-md-0 py-2 ">
            <div class="col-md-2 col-12 bar-bottom">
                <p>
                    <span class="fw-semibold">London</span>, (SW1A 1AA)
                    <span class="fw-semibold">United Kingdom</span>
                </p>
            </div>

            <div
                class="col-md-2 col-12 d-flex flex-column justify-content-md-center align-items-md-center justify-content-start align-items-start gap-2 bar-bottom">
                <p class="success-stone mb-0" style="color: black !important;">ETD: 15 mins</p>
                <p style="text-align: center !important;">OnSite (pick up)</p>
            </div>

            <div
                class="col-md-2 col-12 d-flex flex-column justify-content-md-center align-items-md-center justify-content-start align-items-start gap-2 bar-bottom">
                <p class="warning-stone mb-0" style="color: black !important;">Behind ETA: 15 mins</p>
                <p style="text-align: center !important;">OnSite (Delivery)</p>
            </div>

            <div class="col-md-2 col-12 bar-bottom">
                <p>
                    <span class="fw-semibold">London</span>, (SW1A 1AA)
                    <span class="fw-semibold">United Kingdom</span>
                </p>
            </div>
        </div>
    </div>

</div>
import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { DarkModeService } from '../../services/dark-mode.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

declare const H: any;


@Component({
  selector: 'app-live-track',
  templateUrl: './live-track.component.html',
  styleUrls: ['./live-track.component.css', '../tracking/tracking.component.css', '../bookings/bookings.component.css']
})
export class LiveTrackComponent {
  private readonly apiKey = 'Uu1o9J-f9a1I3KTzQbRnvf22G75lArG8GYHWpepD3GU';
  private map: any;
  private defaultLayers: any;
  private subscription: Subscription | null = null;
  private isDarkMode = false;

  private vehicleMarker: any = null;

  constructor(
    private renderer: Renderer2,
    private darkModeService: DarkModeService,
    public dialogRef: MatDialogRef<LiveTrackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  dataDialog = false;

  fromLocation: any = { lat: '', lng: '' };
  toLocation: any = { lat: '', lng: '' };

  ngOnInit(): void {
    if (this.data?.booking) {
      this.dataDialog = this.data.booking;
    } else if (this.data?.from && this.data?.to) {
      this.fromLocation = this.data.from;
      this.toLocation = this.data.to;

    }


    this.loadMapScripts().then(() => {
      this.initializeMap();
      const london = this.fromLocation; // London

      const birmingham = this.toLocation; // Birmingham

      // this.addOriginAndDestinationMarkers(london, birmingham);
      this.calculateRoute(london, birmingham);
    });
    this.subscribeToDarkMode();
  }

  private subscribeToDarkMode(): void {
    this.subscription = this.darkModeService.darkMode$.subscribe((isDarkMode) => {
      this.isDarkMode = isDarkMode;
      this.setStyle();
    });
  }


  private loadMapScripts(): Promise<void> {
    const scripts = [
      'https://js.api.here.com/v3/3.1/mapsjs-core.js',
      'https://js.api.here.com/v3/3.1/mapsjs-service.js',
      'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
      'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
      'https://js.api.here.com/v3/3.1/mapsjs-clustering.js',
    ];

    return scripts.reduce((promise, src) =>
      promise.then(() => this.loadScript(src)), Promise.resolve());
  }

  private loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject();
      this.renderer.appendChild(document.body, script);
    });
  }


  private initializeMap(): void {
    const platform = new H.service.Platform({ apikey: this.apiKey });
    this.defaultLayers = platform.createDefaultLayers();
    this.map = new H.Map(
      document.getElementById('map')!,
      this.defaultLayers.vector.normal.map,
      {

        center: this.toLocation, // Centered over London
        zoom: 10,
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
      }
    );
    // Create custom icons for origin, destination, and the moving truck
    const originIcon = new H.map.Icon('../../../assets/icons/originIcon.png', { size: { w: 32, h: 32 } });
    const destinationIcon = new H.map.Icon('../../../assets/icons/destinationIcon.png', { size: { w: 32, h: 32 } });

    const london = this.fromLocation; // London
    const birmingham = this.toLocation; // Birmingham




    const originMarker = new H.map.Marker(london, { icon: originIcon });
    const destinationMarker = new H.map.Marker(birmingham, { icon: destinationIcon });

    this.map.addObjects([originMarker, destinationMarker]);

    this.setupMapEvents();
    this.setupMapUI();
    this.setStyle();
    // this.startClustering(this.map, airports);
  }


  private calculateRoute(from: { lat: number; lng: number }, to: { lat: number; lng: number }): void {
    const platform = new H.service.Platform({ apikey: this.apiKey });
    const router = platform.getRoutingService(null, 8);

    const routingParameters = {
      routingMode: 'fast',
      transportMode: 'car',
      origin: `${from.lat},${from.lng}`,
      destination: `${to.lat},${to.lng}`,
      return: 'polyline,turnByTurnActions,actions,instructions,travelSummary'
    };

    router.calculateRoute(routingParameters, (result: any) => {
      if (result.routes && result.routes.length > 0) {
        const route = result.routes[0];
        this.addRouteToMap(route);
        this.simulateLiveMovement(route);
      } else {
        console.error('No routes found.');
      }
    }, (error: any) => {
      console.error('Error calculating route:', error);
    });
  }


  private addRouteToMap(route: any): void {
    const lineString = new H.geo.LineString();

    route.sections.forEach((section: any) => {
      const polyline = H.geo.LineString.fromFlexiblePolyline(section.polyline);
      const latLngAltArray = polyline.getLatLngAltArray();

      for (let i = 0; i < latLngAltArray.length; i += 3) {
        const lat = latLngAltArray[i];
        const lng = latLngAltArray[i + 1];
        lineString.pushPoint({ lat, lng });
      }
    });

    const routeLine = new H.map.Polyline(lineString, {
      style: { strokeColor: '#174a87', lineWidth: 5 },
      smoothing: true // Enable smoothing for a smoother path
    });

    this.map.addObject(routeLine);

    this.map.getViewModel().setLookAtData({ bounds: routeLine.getBoundingBox(), zoom: 15 });

  }

  private addVehicleMarker(lat: number, lng: number): void {
    if (this.vehicleMarker) {
      this.map.removeObject(this.vehicleMarker);
    }

    const truckIcon = new H.map.Icon('../../../assets/icons/vehicleLocator.png', { size: { w: 52, h: 52 } });
    this.vehicleMarker = new H.map.Marker({ lat, lng }, { icon: truckIcon });
    this.map.addObject(this.vehicleMarker);
  }

  private updateVehicleLocation(lat: number, lng: number): void {
    if (this.vehicleMarker) {
      this.vehicleMarker.setGeometry({ lat, lng });
    } else {
      this.addVehicleMarker(lat, lng);
    }
  }

  private simulateLiveMovement(route: any): void {
    const coordinates: any[] = [];

    route.sections.forEach((section: any) => {
      const polyline = H.geo.LineString.fromFlexiblePolyline(section.polyline);
      polyline.getLatLngAltArray().forEach((coord: any, index: any) => {
        if (index % 3 === 0) {
          coordinates.push({ lat: coord, lng: polyline.getLatLngAltArray()[index + 1] });
        }
      });
    });

    let index = 0;
    const intervalId = setInterval(() => {
      if (index < coordinates.length) {
        this.updateVehicleLocation(coordinates[index].lat, coordinates[index].lng);
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 100);
  }


  private setupMapEvents(): void {
    const mapEvents = new H.mapevents.MapEvents(this.map);
    new H.mapevents.Behavior(mapEvents);

    this.map.addEventListener('tap', (evt: any) => {
      // console.log(evt.type, evt.currentPointer.type);
    });

    this.map.addEventListener('dragstart', () => {
      this.map.getViewPort().element.style.cursor = 'grabbing';
    });

    this.map.addEventListener('dragend', () => {
      this.map.getViewPort().element.style.cursor = 'grab';
    });

    window.addEventListener('resize', () => this.map.getViewPort().resize());
  }

  private setupMapUI(): void {
    this.map.getViewPort().setPadding(50, 50, 50, 50);
    H.ui.UI.createDefault(this.map, this.defaultLayers);
    this.map.getViewPort().element.style.cursor = 'grab';
  }

  private setStyle(): void {
    if (!this.map) return;

    const provider = this.map.getBaseLayer().getProvider();
    const styleUrl = this.isDarkMode
      ? 'https://heremaps.github.io/maps-api-for-javascript-examples/change-style-at-load/data/dark.yaml'
      : 'https://js.api.here.com/v3/3.1/styles/omv/normal.day.yaml';

    const style = new H.map.Style(styleUrl, 'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);
  }


  onCloseClick(data: any) {
    this.dialogRef.close(data);
  }
}
